import React from "react"

import {
    InstantSearch,
    ToggleRefinement
  } from "react-instantsearch-dom"

  function IncludeSold(props) {
    return (
      <InstantSearch
          indexName={props.indexName}
          searchClient={props.searchClient}
          searchState={props.searchState}
          onSearchStateChange={props.onSearchStateChange}
          createURL={props.createURL}
          routing="true"
        >
          <div className="property-filter_check">
            <ToggleRefinement
                attribute="status"
                label="Include Sold / SSTC"
                value={['Sold']}
                // defaultRefinement={["Sold"]}
            />
          </div>
          <div className="d-none">
            <ToggleRefinement
                attribute="status"
                label="Sold STC"
                value={['Sold STC']}
                // defaultRefinement={['Sold STC']}
            />
          </div>
      </InstantSearch>
    )
  }
  export default IncludeSold